import React from 'react';
import PropTypes from 'prop-types';
import Layout from 'components/layout';
import Box from 'components/box';
import Title from 'components/title';
import { graphql } from 'gatsby';
import Kontakty from 'components/kontakty';
import FullWidth from 'components/fullwidth';

const Kontakt = ({ data }) => (
  <Layout>
    <FullWidth>
      <Box>
        <Title as="h2" size="large">
          {data.kontaktyJson.title}
        </Title>
      </Box>
    </FullWidth>
    <Kontakty items={data.kontaktyJson.kontakty} />
  </Layout>
);

Kontakt.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Kontakt;

export const query = graphql`
  query KontaktyQuery {
    kontaktyJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      kontakty {
        name
        occupation
        phone1
        phone2
        email
      }
    }
  }
`;
