import React from 'react';
import PropTypes from 'prop-types';

const ItemLink = ({ name, occupation, phone1, phone2, email }) => (
  <figure>
    <figcaption>
      <strong>{name}</strong>
      <div className="occupation">{occupation}</div>
      <div className="phone1">{phone1}</div>
      <div className="phone2">{phone2}</div>
      <div className="email">
        <a href={'mailto:' + email}>{email}</a>
      </div>
    </figcaption>
  </figure>
);

ItemLink.propTypes = {
  name: PropTypes.string,
  occupation: PropTypes.string,
  phone1: PropTypes.string,
  phone2: PropTypes.string,
  email: PropTypes.string,
};

export default ItemLink;
