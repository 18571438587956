import React from 'react';
import PropTypes from 'prop-types';
import Item from 'components/kontakty/item';
import { Container } from './kontakty.css';

const Kontakty = ({ items }) => (
  <div className="kontakty-wrapper">
    <Container className="kontakty">
      {items.map((item, i) => (
        <Item {...item} key={i} />
      ))}
    </Container>
  </div>
);

Kontakty.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Kontakty;
